<template>
    <admin-table title="异常数据" @search="handleSearch" v-model="table" :loading="loading">
        <el-table-column type="index" width="50" align="center"/>
        <el-table-column align="center"
                         v-for="(v, idx) in table.columns"
                         :prop="v.field"
                         :label="v.title"
                         :width="v.width"
                         :key="idx">
        </el-table-column>
    </admin-table>
</template>
<script>
    export default {
        data() {
            return {
                loading: false,
                table: {
                    search: [
                        {
                            type: 'date',
                            key: 'date',
                            value: this.$route.query.date||'',
                            placeholder: '预约日期'
                        },
                        {
                            type: 'input',
                            key: 'name',
                            placeholder: '患者姓名'
                        },
                        {
                            type: 'input',
                            key: 'diagnosis',
                            value: '',
                            placeholder: '诊断名'
                        },
                        {
                            type: 'input',
                            key: 'doctor',
                            value: this.$route.query.doctor||'',
                            placeholder: '医生姓名'
                        }
                    ],
                    columns: [
                        {title: '手术日期', field: 'date', width: ''},
                        {title: '预约时间', field: 'time', width: ''},
                        {title: '患者姓名', field: 'name', width: ''},
                        {title: '联系电话', field: 'phone', width: ''},
                        {title: '手术医生', field: 'doctor', width: ''},
                        {title: '原因', field: 'msg', width: ''},
                    ],
                    data: [],
                    total: 0,
                },
            }
        },
        methods: {
            async getData(e) {
                this.loading = true
                const resp = await this.$http.get('/surgery/appoint/outliers', {params: e})
                this.table.data = resp.data.data.data
                this.table.total = resp.data.data.total
                this.loading = false
            },
            async handleDelete(idx, row) {
                this.$refs[`popover-${idx}`].doClose()
                const resp = await this.$http.delete(`/surgery/appoint/${row.id}`)
                if (resp.data.code == 200) this.$message.success("删除成功")
                this.getData()
            },
            handleEdit(idx, row) {
                this.$router.push(`/main/appoint/form?id=${row.id}`)
            },
            handleNotice(idx, row) {
                this.$router.push(`/main/appoint/notice?id=${row.id}`)
            },
            handleSearch(e) {
                this.getData(e)
            },
        },
        mounted() {
            // var now = new Date()
            // var year = now.getFullYear() // 得到年份
            // var month = now.getMonth() // 得到月份
            // var date = now.getDate() // 得到日期
            // month = month + 1
            // month = month.toString().padStart(2, '0')
            // date = date.toString().padStart(2, '0')
            // var defaultDate = `${year}-${month}-${date}`
            // this.$set(this.form, 'date', defaultDate)
            let params = {
                page: 1,
                pageSize: 20,
            }
            this.table.search.forEach(e=> {
                params[e.key] = e.value ||''
            })
            this.getData(params)
        }
    }
</script>
<style lang="scss" scoped>
</style>
